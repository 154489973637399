body {
  background-image: url(../assets/background_pixel.png);

  background-size: cover;
  background-repeat: repeat-y;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fa-fire-alt{
  color: #0044ff;
}

.fa-heart {
  color: rgb(255, 0, 0);
}

.manaShow{
color: #9fddfa;
}

.icon-text{
color: white;
}

.healShow {
color: rgb(246, 191, 191);
}
.btn{
padding: 0.1em 0.2rem !important ;
}

.fa-bomb {
  color: black;
}

body {
  background-color: #232323;
}
.selected{
  background-color: #212121;
}

.badge-danger {
position: absolute;
font-size: 30;
}



/*
-----------------------------
Animation degats 
-----------------------------
*/
.animationAffichageDesDegats{
  animation: animationAffichageDesDegats linear 2.5s;
  animation-iteration-count: 1;
  transform-origin: 50% 50%;
  animation-fill-mode:forwards; /*when the spec is finished*/
  -webkit-animation: animationAffichageDesDegats linear 2.5s;
  -webkit-animation-iteration-count: 1;
  -webkit-transform-origin: 50% 50%;
  -webkit-animation-fill-mode:forwards; /*Chrome 16+, Safari 4+*/ 
  -moz-animation: animationAffichageDesDegats linear 2.5s;
  -moz-animation-iteration-count: 1;
  -moz-transform-origin: 50% 50%;
  -moz-animation-fill-mode:forwards; /*FF 5+*/
  -o-animation: animationAffichageDesDegats linear 2.5s;
  -o-animation-iteration-count: 1;
  -o-transform-origin: 50% 50%;
  -o-animation-fill-mode:forwards; /*Not implemented yet*/
  -ms-animation: animationAffichageDesDegats linear 2.5s;
  -ms-animation-iteration-count: 1;
  -ms-transform-origin: 50% 50%;
  -ms-animation-fill-mode:forwards; /*IE 10+*/
}

@keyframes animationAffichageDesDegats{
  1% {
    transform:  translate(-23px,7px)  rotate(1deg) scaleX(1.00) scaleY(1.00) ;
  }
  12% {
    transform:  translate(-23px,13px)  rotate(-8deg) scaleX(3.00) scaleY(3.00) ;
  }
  18% {
    transform:  translate(-23px,-7px)  rotate(-12deg) scaleX(5.00) scaleY(5.00) ;
  }
  29% {
    opacity:0.81;
    transform:  translate(-23px,-54px)  rotate(-20deg) scaleX(1.50) scaleY(1.50) ;
  }
  73% {
    opacity:0.34;
    transform:  translate(-23px,-125px)  rotate(-60deg) ;
  }
  100% {
      opacity:0;
    transform:  translate(-23px,-200px)  ;
  }
  101% {
    opacity:0;
    transform:  translate(-23px,-200px)  rotate(-60deg) scaleX(1.50) scaleY(1.50) ;
  }
}

@-moz-keyframes animationAffichageDesDegats{
  1% {
    -moz-transform:  translate(-23px,7px)  rotate(1deg) scaleX(1.00) scaleY(1.00) ;
  }
  12% {
    -moz-transform:  translate(-23px,13px)  rotate(-8deg) scaleX(3.00) scaleY(3.00) ;
  }
  18% {
    -moz-transform:  translate(-23px,-7px)  rotate(-12deg) scaleX(5.00) scaleY(5.00) ;
  }
  29% {
    opacity:0.81;
    -moz-transform:  translate(-23px,-54px)  rotate(-20deg) scaleX(1.50) scaleY(1.50) ;
  }
  73% {
    opacity:0.34;
    -moz-transform:  translate(-23px,-125px)  rotate(-60deg) ;
  }
  100% {
      opacity:0;
    -moz-transform:  translate(-23px,-200px)  ;
  }
  101% {
    opacity:0;
    -moz-transform:  translate(-23px,-200px)  rotate(-60deg) scaleX(1.50) scaleY(1.50) ;
  }
}

@-webkit-keyframes animationAffichageDesDegats {
  1% {
    -webkit-transform:  translate(-23px,7px)  rotate(1deg) scaleX(1.00) scaleY(1.00) ;
  }
  12% {
    -webkit-transform:  translate(-23px,13px)  rotate(-8deg) scaleX(3.00) scaleY(3.00) ;
  }
  18% {
    -webkit-transform:  translate(-23px,-7px)  rotate(-12deg) scaleX(5.00) scaleY(5.00) ;
  }
  29% {
    opacity:0.81;
    -webkit-transform:  translate(-23px,-54px)  rotate(-20deg) scaleX(1.50) scaleY(1.50) ;
  }
  73% {
    opacity:0.34;
    -webkit-transform:  translate(-23px,-125px)  rotate(-60deg) ;
  }
  100% {
      opacity:0;
    -webkit-transform:  translate(-23px,-200px)  ;
  }
  101% {
    opacity:0;
    -webkit-transform:  translate(-23px,-200px)  rotate(-60deg) scaleX(1.50) scaleY(1.50) ;
  }
}

@-o-keyframes animationAffichageDesDegats {
  1% {
    -o-transform:  translate(-23px,7px)  rotate(1deg) scaleX(1.00) scaleY(1.00) ;
  }
  12% {
    -o-transform:  translate(-23px,13px)  rotate(-8deg) scaleX(3.00) scaleY(3.00) ;
  }
  18% {
    -o-transform:  translate(-23px,-7px)  rotate(-12deg) scaleX(5.00) scaleY(5.00) ;
  }
  29% {
    opacity:0.81;
    -o-transform:  translate(-23px,-54px)  rotate(-20deg) scaleX(1.50) scaleY(1.50) ;
  }
  73% {
    opacity:0.34;
    -o-transform:  translate(-23px,-125px)  rotate(-60deg) ;
  }
  100% {
      opacity:0;
    -o-transform:  translate(-23px,-200px)  ;
  }
  101% {
    opacity:0;
    -o-transform:  translate(-23px,-200px)  rotate(-60deg) scaleX(1.50) scaleY(1.50) ;
  }
}

@-ms-keyframes animationAffichageDesDegats {
  1% {
    -ms-transform:  translate(-23px,7px)  rotate(1deg) scaleX(1.00) scaleY(1.00) ;
  }
  12% {
    -ms-transform:  translate(-23px,13px)  rotate(-8deg) scaleX(3.00) scaleY(3.00) ;
  }
  18% {
    -ms-transform:  translate(-23px,-7px)  rotate(-12deg) scaleX(5.00) scaleY(5.00) ;
  }
  29% {
    opacity:0.81;
    -ms-transform:  translate(-23px,-54px)  rotate(-20deg) scaleX(1.50) scaleY(1.50) ;
  }
  73% {
    opacity:0.34;
    -ms-transform:  translate(-23px,-125px)  rotate(-60deg) ;
  }
  100% {
      opacity:0;
    -ms-transform:  translate(-23px,-200px)  ;
  }
  101% {
    opacity:0;
    -ms-transform:  translate(-23px,-200px)  rotate(-60deg) scaleX(1.50) scaleY(1.50) ;
  }
}

/*
--------------------------
fin animation degats
--------------------------
*/





/*
-------------------------
animationDegatsCard
-------------------------
*/
.animationDegatsCard{
  -webkit-animation: shake-bottom 0.4s ease-in-out both;
          animation: shake-bottom 0.4s ease-in-out both;
}

/* ----------------------------------------------
* Generated by Animista on 2018-12-28 13:58:53
* w: http://animista.net, t: @cssanimista
* ---------------------------------------------- */

/**
* ----------------------------------------
* animation shake-bottom
* ----------------------------------------
*/
@-webkit-keyframes shake-bottom {
0%,
100% {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
}
10% {
  -webkit-transform: rotate(1deg);
          transform: rotate(1deg);
}
20%,
40%,
60% {
  -webkit-transform: rotate(-2deg);
          transform: rotate(-2deg);
}
30%,
50%,
70% {
  -webkit-transform: rotate(2deg);
          transform: rotate(2deg);
}
80% {
  -webkit-transform: rotate(-1deg);
          transform: rotate(-1deg);
}
90% {
  -webkit-transform: rotate(1deg);
          transform: rotate(1deg);
}
}
@keyframes shake-bottom {
0%,
100% {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
}
10% {
  -webkit-transform: rotate(1deg);
          transform: rotate(1deg);
}
20%,
20%,
60% {
  -webkit-transform: rotate(-2deg);
          transform: rotate(-2deg);
}
30%,
50%,
70% {
  -webkit-transform: rotate(2deg);
          transform: rotate(2deg);
}
80% {
  -webkit-transform: rotate(-1deg);
          transform: rotate(-1deg);
}
90% {
  -webkit-transform: rotate(1deg);
          transform: rotate(1deg);
}
}

/*
-------------------------
Fin animationDegatsCard
-------------------------
*/



/*
------------------
animationHeal
------------------
*/

.AnimationHeal{
background: linear-gradient(175deg, #67db67, #ffffff, #67db67, #ffffff, #67db67, #e0ffe0);
background-size: 1200% 1200%;


-webkit-animation: AnimationHeal 1s ease 1;
-moz-animation: AnimationHeal 1s ease 1;
animation: AnimationHeal 1s ease 1;
}



@-webkit-keyframes AnimationHeal {
  0%{background-position:47% 0%}
  50%{background-position:54% 100%}
  100%{background-position:47% 0%}
}
@-moz-keyframes AnimationHeal {
  0%{background-position:47% 0%}
  50%{background-position:54% 100%}
  100%{background-position:47% 0%}
}
@keyframes AnimationHeal { 
  0%{background-position:47% 0%}
  50%{background-position:54% 100%}
  100%{background-position:47% 0%}
}


.card-monstre {
margin-top: 10px;
}

/*#### Bouton COMBO ####*/
.btn-combo {
position: absolute;
width: 200px;
top: 50px;
margin: auto;
left: 35%;
height: 85px;
background-color: #f02c0c !important;
font-size: 25px;
font-weight: bold;
}

.pulse {
cursor: pointer;
animation: pulse 1s infinite;
}
.pulse:hover {
animation: none;
}

@-webkit-keyframes pulse {
0% {
  -webkit-box-shadow: 0 0 0 0px rgba(220,20,60, 0.4);
}
70% {
    -webkit-box-shadow: 0 0 0 30px rgba(220,20,60, 0);
}
100% {
    -webkit-box-shadow: 0 0 0 0px rgba(220,20,60, 0);
}
}
@keyframes pulse {
0% {
  -moz-box-shadow: 0 0 0 0 rgba(220,20,60, 0.4);
  box-shadow: 0 0 0 0 rgba(220,20,60, 0.4);
}
70% {
    -moz-box-shadow: 0 0 0 0 rgba(220,20,60, 0);
    box-shadow: 0 0 0 30px rgba(220,20,60, 0);
}
100% {
    -moz-box-shadow: 0 0 0 0 rgba(220,20,60, 0);
    box-shadow: 0 0 0 0 rgba(220,20,60, 0);
}
}
/*#### End Bouton COMBO ####*/

.bar-taille {
width: 65%;
margin: auto;
display: block;
}

.progress {
  height: 30px;
}

.progress-bar {
display: -ms-flexbox;
display: flex;
-ms-flex-direction: column;
flex-direction: column;
-ms-flex-pack: center;
justify-content: center;
color: #fff;
text-align: center;
white-space: nowrap;
background-color: #007bff;
transition: width .6s ease;
height: 100%;

}

.progress-bar > i {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg-danger {
background-color: #ff3547 !important;
height: 100%;
}

.cantPlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  background-color: #1717174a;
  
}

.dragon-img {
  object-fit: contain;
  width: 100%;
}

.dragon-img-ctn {
  width: 320px;
  /* object-fit: contain; */
}

.player-card {
  background-color: #282c3431;
  border: 1px solid rgba(255, 255, 255, 0.247);
  border-radius: 5px;
}

.player-card img {
  width: 220px;
  object-fit: contain;
}

.card-player {
  .card-player {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  @media screen and (max-width: 768px) {
   .card-player {
   flex-direction: column;
   }
  }
}







#testCarouselle {
height: 100vh;
}



@import url("https://fonts.googleapis.com/css?family=Hind:400,700");
html, body {
font-family: 'Hind', sans-serif;
margin: 0;
padding: 0;
width: 100%;
height: 100%;
}

img {
max-width: 100%;
height: 250px;
display: block;
}

h3 {
text-align: center;
font-weight: 400;
margin-bottom: 0;
}

.carousel-wrapper {
position: relative;
width: 70%;
height: 70%;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
background-color: #FFFFFF;
background-image: linear-gradient(#FFFFFF 50%, #FFFFFF 50%, #F0F3FC 50%);
box-shadow: 0px 12px 39px -19px rgba(0, 0, 0, 0.75);
overflow: hidden;
}
.carousel-wrapper .carousel {
position: absolute;
top: 50%;
transform: translateY(-50%);
width: 100%;
height: auto;
}
.carousel-wrapper .carousel .carousel-cell {
padding: 10px;
background-color: #FFFFFF;
width: 20%;
height: auto;
min-width: 120px;
margin: 0 20px;
transition: transform 500ms ease;
}
.carousel-wrapper .carousel .carousel-cell .more {
display: block;
opacity: 0;
margin: 5px 0 15px 0;
text-align: center;
font-size: 10px;
color: #CFCFCF;
text-decoration: none;
transition: opacity 300ms ease;
}
.carousel-wrapper .carousel .carousel-cell .more:hover, .carousel-wrapper .carousel .carousel-cell .more:active, .carousel-wrapper .carousel .carousel-cell .more:visited, .carousel-wrapper .carousel .carousel-cell .more:focus {
color: #CFCFCF;
text-decoration: none;
}
.carousel-wrapper .carousel .carousel-cell .line {
position: absolute;
width: 2px;
height: 0;
background-color: black;
left: 50%;
margin: 5px 0 0 -1px;
transition: height 300ms ease;
display: block;
}
.carousel-wrapper .carousel .carousel-cell .price {
position: absolute;
font-weight: 700;
margin: 45px auto 0 auto;
left: 50%;
transform: translate(-50%);
opacity: 0;
transition: opacity 300ms ease 300ms;
}
.carousel-wrapper .carousel .carousel-cell .price sup {
top: 2px;
position: absolute;
}
.carousel-wrapper .carousel .carousel-cell.is-selected {
transform: scale(1.2);
}
.carousel-wrapper .carousel .carousel-cell.is-selected .line {
height: 35px;
}
.carousel-wrapper .carousel .carousel-cell.is-selected .price, .carousel-wrapper .carousel .carousel-cell.is-selected .more {
opacity: 1;
}
.carousel-wrapper .flickity-page-dots {
display: none;
}
.carousel-wrapper .flickity-viewport, .carousel-wrapper .flickity-slider {
overflow: visible;
}



